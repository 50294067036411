<template>
    <div>
        <div>
            <b-g-decorate></b-g-decorate>
        </div>

        <!--        返回按钮-->
        <button-back/>
        <!--        steps-->
        <div>
            <el-row type="flex" justify="center" align="center">
                <div class="charge-steps-group">
                    <svg-icon class-name="icon-step"
                              :icon="curSetp == 0?'icon_steps':'icon_steps_default'"/>
                    <span class="label">第一步</span>
                </div>
                <div class="charge-steps-group">
                    <svg-icon class-name="icon-step"
                              :icon="curSetp == 1?'icon_steps':'icon_steps_default'"/>
                    <span class="label">第二步</span>
                </div>
            </el-row>
        </div>

        <div v-if="curSetp == 0" class="charge-form">
            <!--    info from-->
            <el-form ref="info-form" :model="infoFormData" :rules="infoRules" label-position="top" :status-icon="true"
                     :inline-message="true" :show-message="false">

                <el-card class="charge-card">
                    <div class="title"> 学员信息</div>
                    <el-form-item prop="school" label="学校信息">
                        <el-select v-model="infoFormData.school" @change="(v)=>requestListOptions({type:1,parentId:v})"
                                   placeholder="请选择">
                            <el-option
                                    v-for="item in schoolOptions"
                                    :key="item.deptId"
                                    :label="item.deptName"
                                    :value="item.deptId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="gradle" label="年级信息">
                        <el-select v-model="infoFormData.gradle" @change="(v)=>requestListOptions({type:2,parentId:v})"
                                   placeholder="请选择">
                            <el-option
                                    v-for="item in gradleOptions"
                                    :key="item.deptId"
                                    :label="item.deptName"
                                    :value="item.deptId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="classes" label="班级信息">
                        <el-select v-model="infoFormData.classes" placeholder="请选择">
                            <el-option
                                    v-for="item in classesOptions"
                                    :key="item.deptId"
                                    :label="item.deptName"
                                    :value="item.deptId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="mobile" label="学生姓名">
                        <el-input v-model="infoFormData.student" placeholder="请输入学生姓名"></el-input>
                    </el-form-item>
                </el-card>
            </el-form>
        </div>

        <!--     缴费项目   -->
        <div v-else-if="curSetp == 1" class="charge-form">
            <!--    info from-->
            <el-form ref="pay-form" :model="payFormData" :rules="payRules" label-position="top" :status-icon="true"
                     :inline-message="true" :show-message="false">

                <el-card class="charge-card">
                    <div class="title"> 缴费信息</div>
                    <div class="charge-card-desc"> 请选择缴费项目</div>
                    <div>
                        <div v-for="(item,i) in payList" :key="i" class="pay-form-item"
                             :class="{'checked':item == payFormData.payItem}"
                             @click="payFormData.payItem = item">
                            <div class="pay-form-item-title">¥{{item.fee}}</div>
                            <div class="pay-form-item-desc">{{item.itemName}}</div>
                        </div>
                    </div>

                    <!--                   &#45;&#45;暂时去掉-->
                    <!--                    <div class="charge-card-desc"> 请选择支付方式</div>-->
                    <!--                    <el-row class="m-3" type="flex">-->
                    <!--                        <div class="m-3" @click="setPayChannel(0)">-->
                    <!--                            <svg-icon class-name="icon-pay-channel"-->
                    <!--                                      :icon="payFormData.payChannel == 0?'icon_weixin_active':'icon_weixin'"/>-->
                    <!--                        </div>-->
                    <!--                        <div class="m-3" @click="setPayChannel(1)">-->
                    <!--                            <svg-icon class-name="icon-pay-channel"-->
                    <!--                                      :icon="payFormData.payChannel == 1?'icon_alipay_active':'icon_alipay'"/>-->

                    <!--                        </div>-->
                    <!--                    </el-row>-->

                    <el-form-item prop="isCheckProtocol" class="layout-protocol" v-if="false">
                        <el-checkbox v-model="payFormData.isCheckProtocol" class="protocol-check" checked></el-checkbox>
                        我已阅读并同意<span class="protocol-text" @click="toProtocolPage">《缴费规则》</span>
                    </el-form-item>
                </el-card>
            </el-form>
        </div>

        <div v-if="curSetp == 0">
            <!--   button-->
            <el-button class="submit-button" @click="requestNext()">下一步</el-button>
        </div>
        <div v-else-if="curSetp == 1">
            <el-button class="submit-button" @click="commitForm()">提交</el-button>
            <el-button class="cancel-button" @click="resetPayForm()">上一步</el-button>
        </div>
    </div>
</template>

<script>
    import BGDecorate from "@/components/BGDecorate";
    import ButtonBack from "@/components/ButtonBack";

    export default {
        name: "ChargePage",
        components: {
            BGDecorate, ButtonBack
        },
        data() {
            return {
                curSetp: 0,
                //---- 学校信息了iron
                schoolOptions: [],
                classesOptions: [],
                gradleOptions: [],
                infoFormData: {
                    student: "",
                    school: "",
                    classes: "",
                    gradle: "",
                },
                infoRules: {
                    classes: [
                        {required: true, message: '请完善班级信息', trigger: 'blur'},
                    ],
                    student: [
                        {required: true, message: '请输入学生信息', trigger: 'blur'},
                    ],
                },
                //---- 缴费项目
                payList: [],
                payFormData: {
                    payItem: null,
                    payChannel: 0,
                    isCheckProtocol: false,
                },
                payRules: {
                    payItem: [
                        {required: true, message: '请选择缴费项目', trigger: 'blur'},
                    ],
                    payChannel: [
                        {required: true, message: '请输入支付方式', trigger: 'blur'},
                    ],
                    isCheckProtocol: [
                        {
                            type: 'boolean',
                            required: true, message: '请阅读并同意《注册协议》', validator: function (rule, value, callback) {
                                if (!value) {
                                    callback(new Error('请阅读并同意《注册协议》'));
                                }
                                callback();
                            },
                        },
                    ]
                },
            }
        },
        created() {
            this.requestListOptions({parentId: '201'});
        },
        methods: {
            setPayChannel(val) {
                this.payFormData.payChannel = val
            },
            commitForm() {
                let prodType = this.payFormData.payChannel == 0 ? "WECHATAPP" : "ALIPAYAPP";
                this.$api.pay({itemId: this.payFormData.payItem.payDetailId, prodType: prodType}).then(res => {
                    console.log("@TEST...", res);
                    if (res['qrCodeUrl']) {
                        window.location.href = res['qrCodeUrl']
                    } else {
                        this.$notify.error("调用支付失败，请重试");
                    }
                }).catch(e => {
                    this.$notify.error(e.message);
                })
            },
            resetPayForm() {
                this.curSetp = 0;
                this.payFormData = {
                    payItem: null,
                    payChannel: 0,
                }
                this.payList = []
            },
            requestNext() {
                let _this = this;
                _this.$refs["info-form"].validate((valid, error) => {
                    if (valid) {
                        //获取交易信息列表
                        _this.$api.queryPayChargeDetail({
                            classId: _this.infoFormData.classes,
                            studentName: _this.infoFormData.student
                        }).then(res => {
                            console.log("请求结果--：", res)
                            _this.curSetp = 1
                            //--
                            _this.payList = res
                            if (res[0]) {
                                _this.payFormData.payItem = res[0];
                            }
                        }).catch(err => {
                            _this.$notify.error(err.message)
                        })
                    } else {
                        _this._showErrorValidMsg(error)
                    }
                })
            },
            //---获取列表信息项
            requestListOptions({type = 0, parentId}) {
                this.$api.queryDeptList({parentId: parentId}).then(res => {
                    this.classesOptions.length = 0
                    this.infoFormData.classes = ""
                    if (type == 0) {
                        this.schoolOptions.length = 0
                        this.gradleOptions.length = 0
                        this.infoFormData.school = ""
                        this.infoFormData.gradle = ""
                        let schoolOption = [];
                        for (let i = 0; i < res.length; i++) {
                          if (res[i].deptId == "203")
                            schoolOption.push(res[i]);
                        }
                        this.schoolOptions = schoolOption
                    } else if (type == 1) {
                        this.gradleOptions.length = 0
                        this.infoFormData.gradle = ""
                        this.gradleOptions = res
                    } else if (type == 2) {
                        this.classesOptions = res
                    }
                }).catch((e) => {
                    this.$notify.error(e.message || "获取列表失败")
                })
            },
            toProtocolPage() {
                this.$router.push({
                    path: '/protocol', query: {
                        type: 'diy',
                        title: '缴费规则',
						content: this.payFormData.payItem['feeRemark'],
                    },
                });
            }
        }
    }
</script>

<style scoped lang="less">


    .icon-pay-channel {
        width: 41px;
        height: 41px;
    }

    .icon-step {
        width: 81px;
        height: 30px;
    }

    .charge-steps-group {
        position: relative;
        margin: 10px;

        .label {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(50% - 14px);
            color: white;
            font-size: 14px;
        }
    }

    .layout-protocol {
        color: @font-desc;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;

        /deep/ .el-checkbox__inner {
            border-color: @color-main;
            background-color: @color-main;
        }

        .protocol-text {
            color: @color-main;
        }
    }

    .charge-card {
        margin: 0px 34px;
        background: #FFFFFF;
        box-shadow: 3px 3px 50px #D6DBDE;
        border-radius: 24px;
        text-align: left;

        .title {
            margin-bottom: 10px;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 20.4884px;
            line-height: 24px;
            color: #000000;
        }

        &-desc {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12.4884px;
            line-height: 15px;
            color: #7D7D7D;
        }
    }

    .charge-form {
        margin-top: 20px;
    }

    /**
    * ----- 支付表单
     */
    .pay-form-item {
        /*background: linear-gradient(141.45deg, #EB905D 5.02%, #FFC530 106.29%);*/
        background: #DAD7D3;
        border-radius: 20px;
        text-align: center;
        padding: 14px;
        margin: 10px 5px;

        &-title {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 23.4884px;
            margin-bottom: 7px;
            line-height: 28px;
            color: #FFFFFF;
        }

        &-desc {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 11.4884px;
            line-height: 13px;
            color: #2D2D2D;
        }
    }

    .pay-form-item.checked {
        background: linear-gradient(141.45deg, #EB905D 5.02%, #FFC530 106.29%);
    }

</style>
