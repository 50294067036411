<template>
    <div class="div-button-back" v-anim-click @animationend="_goBack">
        <svg-icon icon="icon_back" width="40" height="40"/>
    </div>
</template>

<script>
    export default {
        name: "ButtonBack"
    }
</script>

<style scoped>
    .div-button-back {
        text-align: center;
        width: max-content;
        margin: 15px;
        z-index: 99;
    }
</style>
